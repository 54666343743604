import axios from 'axios'
import debounce from 'lodash/debounce'
import { Controller } from 'stimulus'

const MIN_PREFIX_LENGTH = 3

export default class CharacterRelationshipsModalController extends Controller {
    constructor(...args) {
        super(...args)

        this._fetchData = debounce(this._fetchData, 500).bind(this)
    }

    get prefix() {
        return this.prefixInputTarget.value
    }

    set prefix(val) {
        this.prefixInputTarget.value = val
    }

    connect() {
        this.minPrefixSpanTarget.innerText = MIN_PREFIX_LENGTH
        this.searchUrl = this.element.dataset.searchUrl
    }

    searchPrefix() {
        this.updatePrefixVis()
        if(this.prefix.length < MIN_PREFIX_LENGTH) return;

        this.resultsPanelTarget.innerHTML = ""
        this._fetchData()
    }

    updatePrefixVis() {
        if (this.prefix.length < MIN_PREFIX_LENGTH) {
            this.minPrefixDisplayTarget.classList.remove('is-hidden')
            this.displaySpinnerTarget.classList.add('is-hidden')
            this.resultsPanelTarget.classList.add('is-hidden')
        } else {
            this.minPrefixDisplayTarget.classList.add('is-hidden')
            this.displaySpinnerTarget.classList.remove('is-hidden')
            this.resultsPanelTarget.classList.remove('is-hidden')
        }
    }

    open(ev) {
        this.characterInputs = ev.detail
        this.element.classList.add('is-active')
        this.prefixInputTarget.focus()
    }

    close(ev) {
        if (ev.type == 'keydown' && ev.key != 'Escape') return;

        this.prefix = ''
        this.updatePrefixVis()
        this.characterInputs = null
        this.element.classList.remove('is-active')
    }

    setCharacter(ev) {
        ev.preventDefault()
        let { target } = ev
        this.characterInputs.nameDisplay.value = target.innerText
        this.characterInputs.input.value = target.dataset.characterId
        this.close({type: 'manual'})
    }

    _fetchData() {
        axios.get(
            this.searchUrl,
            {params: {search: this.prefix}}
        ).then(res => {
            let characters = res.data.map(([id, name]) => {
                return `<a class="panel-block" data-character-id="${id}"
                          data-action="character-relationships-modal#setCharacter">
                    ${name}
                </a>`
            })

            this.resultsPanelTarget.innerHTML = characters.join("\n")
            this.displaySpinnerTarget.classList.add('is-hidden')
        })
    }
}

CharacterRelationshipsModalController.targets = [
    'prefixInput', 'minPrefixDisplay', 'minPrefixSpan',
    'displaySpinner', 'resultsPanel'
]

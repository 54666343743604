import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

require('../front/styles/front.scss');

import mountNavbar from '../front/components/navbar'
import addFlashClose from '../front/components/flash'
import initAllTabs from '../front/components/tabs'

window.addEventListener('load', ev => {
    let navbar = document.getElementById('topNavbar')
    mountNavbar(navbar);

    addFlashClose()

    initAllTabs()
})

const stmApp = Application.start()
const stmContext = require.context('../front/components/stimulus_controllers', true, /\.js$/)
stmApp.load(definitionsFromContext(stmContext))

const ACTIVE_CLASS = 'is-active'
const HIDDEN_CLASS = 'is-hidden'

class Tab {
    constructor(el) {
        this.el = el
        this.tabsContainers = new Map()
    }

    init() {
        this.tabLinks = this.el.querySelectorAll('li a')
        for(let tl of this.tabLinks) {
            let tabContainer = document.querySelector(tl.attributes['href'].value)
            this.tabsContainers.set(tl, tabContainer)

            tl.addEventListener('click', event => {
                event.preventDefault()
                this.activateTab(tl)
            })
        }

        this.activateTab(this.tabLinks[0])
    }

    activateTab(tabLink) {
        let tc = this.tabsContainers.get(tabLink)
        if(tc == null) return;

        for(let [tl, tc] of this.tabsContainers.entries()) {
            tl.parentElement.classList.remove(ACTIVE_CLASS)
            tc.classList.add(HIDDEN_CLASS)
        }

        tabLink.parentElement.classList.add(ACTIVE_CLASS)
        tc.classList.remove(HIDDEN_CLASS)
    }
}

export function mountTabs(element) {
    let tabLogic = new Tab(element)
    tabLogic.init()
}

export default function initAllTabs() {
    let tabContainers = document.querySelectorAll('.tabs')
    for (let tc of tabContainers) {
        mountTabs(tc)
    }
}

import { Controller } from 'stimulus'
import takeWhile from 'lodash/takeWhile'
import takeRightWhile from 'lodash/takeRightWhile'

export default class CharacterRelationshipsFormController extends Controller {
    initialize() {
        let template = this.relationshipTemplateTarget
        let tmplContent = template.content

        let tmplIndex = Number(tmplContent.querySelector('.relationship-subform__idx').innerText)
        this.templateContent = template.innerHTML
            .replace(new RegExp(`\\[${tmplIndex}\\]`, 'g'), '[{{index}}]')
            .replace(new RegExp(`_${tmplIndex}_`, 'g'), '_{{index}}_')
            .replace(`data-index="${tmplIndex}"`, '')

        this.lastIndex = tmplIndex
    }

    addRelationship() {
        let addHtml = this.templateContent.replace(/{{index}}/g, this.lastIndex)
        this.addButtonTarget.insertAdjacentHTML('beforebegin', addHtml)
        this.lastIndex += 1
    }

    disable(ev) {
        let { target } = ev
        let $subformEls = this._findSubformElements(target)
        $subformEls.addClass('is-hidden')
        let $destroy = $subformEls.filter('input[type=hidden][name$="_destroy]"]')
        $destroy.val(true)
        $subformEls.find('input:not([type=hidden])').attr('disabled', true)
    }

    destroy(ev) {
        let { target } = ev
        let $subformEls = this._findSubformElements(target)
        $subformEls.remove()
    }

    toggleVisibility(ev) {
        ev.preventDefault();
        // this.containerTarget.classList.toggle('is-hidden');
        this.element.classList.toggle('is-closed')
    }

    _findSubformElements(input) {
        let $container = $(input).closest('div')
        let $leftSibs = $container.prevUntil('hr')
        let $rightSibs = $container.nextUntil('hr + *').addBack()

        let $result = $.merge($leftSibs, $rightSibs)

        console.log($result)

        return $result
    }
}

CharacterRelationshipsFormController.targets = [
    'relationshipTemplate', 'addButton', 'container'
]

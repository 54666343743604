import { Controller } from 'stimulus'

const proxyInputHTML = require('raw-loader!./character_relationship_character/proxy_input.html')

export default class CharacterRelationshipsCharacterController extends Controller {
    connect() {
        this.inputTarget.classList.add('relationship-subform__hidden-char-input')
        this.element.insertAdjacentHTML('beforeend', proxyInputHTML)
        this._displayCharacterName(this.element.dataset.characterName)
        this.characterNameTarget.setAttribute('title', this.element.dataset.characterName)
        this.modal = document.getElementById('character_select_modal')
    }

    selectCharacter() {
        let event = new CustomEvent('changecharacter', {
            detail: {
                input: this.inputTarget,
                nameDisplay: this.characterNameTarget
            }
        })
        this.modal.dispatchEvent(event)
    }

    setCharacter({id, name}) {
        this.inputTarget.value = id
        this._displayCharacterName(name)
    }

    _displayCharacterName(name) {
        this.characterNameTarget.value = name;
    }
}

CharacterRelationshipsCharacterController.targets = ['input', 'characterName', 'changeCharacter']

export default function mountNavbar(navbarEl) {
    if(navbarEl == null) { window.console.error("Navbar element not found"); return }

    let burger = navbarEl.querySelector('.navbar-burger')
    let targetId = burger.dataset.target
    let target = document.getElementById(targetId)

    burger.addEventListener('click', ev => {
        burger.classList.toggle('is-active')
        target.classList.toggle('is-active')
    })
}